import { Injectable } from "@angular/core";
import { forkJoin, map, Observable, switchMap } from "rxjs";
import { AuthService } from "@core/services/auth/auth.service";
import { NgxRolesService } from "ngx-permissions";
import { ValidateSignatureService } from "@core/services/auth/validate-signature.service";
import { Permissions } from "@shared/schemas/complete-login";
import { BusinessService } from "@data/services/business.service";
import { MenuService } from "@core/services/auth/menu.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { AsaasLoginOverlayComponent } from "@modules/auth/component/asaas-login-overlay/asaas-login-overlay.component";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";

@Injectable({
	providedIn: "root"
})
export class AuthAsaasService {
	constructor(
		private authService: AuthService,
		private roles: NgxRolesService,
		private validateSignatureService: ValidateSignatureService,
		private businessService: BusinessService,
		private menuService: MenuService,
		private segment: CustomSegmentService,
		private atlasModalService: AtlasModalService
	) {}

	isLoginAsaasByUrl(): boolean {
		return window.location.href.includes("x-api-key");
	}

	getApiKeyFromUrl(): string | null {
		const hashParams = window.location.hash.split("?")[1];
		const params = new URLSearchParams(hashParams);
		return params.get("x-api-key");
	}

	forceLoginByGuard(): Observable<void> {
		const apiKey = this.getApiKeyFromUrl();
		if (!apiKey) {
			return new Observable<void>((observer) => observer.complete());
		}

		this.atlasModalService.openModal(AsaasLoginOverlayComponent, {});

		return forkJoin([
			this.authService.logoutLegacy(),
			this.authService.logout(false),
			this.authService.logoutSupport(false)
		]).pipe(
			switchMap(() => {
				this.authService.logoutUserSupportAccountant();
				this.validateSignatureService.fireDestroyRevalidateSignature();
				this.authService.removeSupportLoggedInAsNormalUser();
				return this.authService.loginByAsaas(apiKey);
			}),
			switchMap(({ loginResponse, permissions }) => {
				this.authService.loginLegacy();
				this.authService.setSession(loginResponse);
				this.setPermissions(permissions);
				this.initializeSegmentTracking();
				this.loadAndSetBusinessData();
				return forkJoin([
					this.loadAndSetBusinessData(),
					this.menuService.loadMenus()
				]);
			}),
			map(([_, menu]) => {
				this.menuService.setMenuList(menu);
				this.atlasModalService.closeModal();
			})
		);
	}

	private setPermissions(permissions: Permissions): void {
		this.roles.flushRolesAndPermissions();
		this.roles.addRoleWithPermissions("", [
			...permissions.modules,
			...permissions.pages,
			...permissions.actions,
			this.authService.getUserType()
		]);
	}

	private loadAndSetBusinessData(): Observable<void> {
		return this.businessService.getBusinessDefault().pipe(
			map((businessDefault) => {
				this.businessService.setBusinessData(businessDefault);
			})
		);
	}

	private initializeSegmentTracking(): void {
		this.segment.track({
			table: "baseerp_login",
			screen: "Login",
			action: "Redirect Login"
		});
	}
}
