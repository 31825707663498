import { html as t, svg as r, mathml as e } from "./lit-html.js";
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const a = Symbol.for(""),
  o = t => {
    if (t?.r === a) return t?._$litStatic$;
  },
  s = t => ({
    _$litStatic$: t,
    r: a
  }),
  i = (t, ...r) => ({
    _$litStatic$: r.reduce((r, e, a) => r + (t => {
      if (void 0 !== t._$litStatic$) return t._$litStatic$;
      throw Error(`Value passed to 'literal' function must be a 'literal' result: ${t}. Use 'unsafeStatic' to pass non-literal values, but\n            take care to ensure page security.`);
    })(e) + t[a + 1], t[0]),
    r: a
  }),
  l = new Map(),
  n = t => (r, ...e) => {
    const a = e.length;
    let s, i;
    const n = [],
      u = [];
    let c,
      $ = 0,
      f = !1;
    for (; $ < a;) {
      for (c = r[$]; $ < a && void 0 !== (i = e[$], s = o(i));) c += s + r[++$], f = !0;
      $ !== a && u.push(i), n.push(c), $++;
    }
    if ($ === a && n.push(r[a]), f) {
      const t = n.join("$$lit$$");
      void 0 === (r = l.get(t)) && (n.raw = n, l.set(t, r = n)), e = u;
    }
    return t(r, ...e);
  },
  u = n(t),
  c = n(r),
  $ = n(e);
export { u as html, i as literal, $ as mathml, c as svg, s as unsafeStatic, n as withStatic };
