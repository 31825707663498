import { noChange as t } from "../lit-html.js";
import { directive as e, Directive as r, PartType as s } from "../directive.js";
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const n = "important",
  i = " !" + n,
  o = e(class extends r {
    constructor(t) {
      if (super(t), t.type !== s.ATTRIBUTE || "style" !== t.name || t.strings?.length > 2) throw Error("The `styleMap` directive must be used in the `style` attribute and must be the only part in the attribute.");
    }
    render(t) {
      return Object.keys(t).reduce((e, r) => {
        const s = t[r];
        return null == s ? e : e + `${r = r.includes("-") ? r : r.replace(/(?:^(webkit|moz|ms|o)|)(?=[A-Z])/g, "-$&").toLowerCase()}:${s};`;
      }, "");
    }
    update(e, [r]) {
      const {
        style: s
      } = e.element;
      if (void 0 === this.ft) return this.ft = new Set(Object.keys(r)), this.render(r);
      for (const t of this.ft) null == r[t] && (this.ft.delete(t), t.includes("-") ? s.removeProperty(t) : s[t] = null);
      for (const t in r) {
        const e = r[t];
        if (null != e) {
          this.ft.add(t);
          const r = "string" == typeof e && e.endsWith(i);
          t.includes("-") || r ? s.setProperty(t, r ? e.slice(0, -11) : e, r ? n : "") : s[t] = e;
        }
      }
      return t;
    }
  });
export { o as styleMap };
