/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function* o(o, t, e = 1) {
  const i = void 0 === t ? 0 : o;
  t ??= o;
  for (let o = i; e > 0 ? o < t : t < o; o += e) yield o;
}
export { o as range };
