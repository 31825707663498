import { inject } from "@angular/core";
import { CanMatchFn, Route, Router, UrlSegment } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";
import { AuthAsaasService } from "@core/services/auth/auth-asaas.service";
import { firstValueFrom } from "rxjs";

export const loggedInAuthGuardFn: CanMatchFn = async (
	_: Route,
	__: UrlSegment[]
) => {
	const authService = inject(AuthService);
	const router = inject(Router);
	const authAsaasService = inject(AuthAsaasService);

	if (authAsaasService.isLoginAsaasByUrl()) {
		await firstValueFrom(authAsaasService.forceLoginByGuard());
	}

	if (authService.getTokenSupportAccountant()) {
		if (authService.isSupportLoggedInAsNormalUser()) {
			authService.removeUserToken();
			authService.removeSupportLoggedInAsNormalUser();
		}
		return router.parseUrl("/suporte");
	}

	if (authService.isLoggedOut() && !authService.getTokenSupportAccountant()) {
		return true;
	}

	return router.parseUrl("/home");
};
