import { inject } from "@angular/core";
import { Router, CanMatchFn, Route } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";

export const authGuardFn: CanMatchFn = (_: Route) => {
	const router = inject(Router);
	const authService = inject(AuthService);

	if (authService.getTokenSupportAccountant()) {
		if (authService.isSupportLoggedInAsNormalUser()) {
			return true;
		}
		return router.parseUrl("/suporte");
	}

	if (authService.isLoginFromAsaas() && !authService.isLoggedIn()) {
		window.location.href = authService.getCompleteAsaasLoginUrl();
		return false;
	}

	if (authService.isLoginFromAsaas() && authService.isLoggedIn()) {
		return true;
	}

	if (authService.isLoggedIn()) {
		return true;
	}
	return router.parseUrl("/pages/auth/login");
};
